import styles from './GRiscoNotionalDetalhe.module.css'
import { Accordion } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { convertDataToText, convertTextoToData } from '../../paginas/Admin/convertData';
import { filterData, SearchType } from 'filter-data';
import GraficoLimpo from '../GraficoLimpo';
import { useRef } from 'react';
import Button from 'react-bootstrap/Button';

// LINHA DO CLIENTE NA TABELA DE CONTROLE DE RISCO
const DetalheNotional = (props) => {

    // VARIÁVEIS DE ESTADO
    const [dadosGrafNotional, setDadosGrafNotional] = useState([])
    const [dadosGrafOTM, setDadosGrafOTM] = useState([])
    const [dadosTipo, setDadosTipo] = useState({
        seco:0,
        trava:0,
        longa:0
    })
    const [notionalTotal, setNotionalTotal] = useState({
        total:0,
        ponderada:props.input.notionalUsado
    })
    const [provisao, setProvisao] = useState({
        provisao:0,
        provisaoQueda:0
    })
    const [baseNotionalAcao, setBaseNotionalAcao] = useState([])
    const [parametersFiltro, setParametersFiltro] = useState([])

    // VARIÁVEIS DE REFERÊNCIA
    const tipoSeco = useRef();
    const tipoLonga = useRef();
    const tipoTrava = useRef();

    // VARIÁVEL DE FONTE DOS DADOS
    const fonte = "sans-serif"
    let RSLocale = Intl.NumberFormat('pt-BR');

    // PROCESSO DE ADIÇÃO NA BASE DA INFORMAÇÕES ATM, ITM E OTM
    var baseCopia = []

    // LOOP NA BASE PARA ADIÇÃO DOS CAMPOS "ATM", "ITM" E "OTM"
    for(let i=0; i<props.base.length;i++){
        // ADICIONA O CAMPO NA BASE CÓPIA
        baseCopia.push(props.base[i])

        // AJUSTA A ESTRATÉGIA DE OPÇÕES DE NULL PARA SECO
        if(baseCopia[i]["Estrategia de Opcoes"] === null){
            baseCopia[i]["Estrategia de Opcoes"] = "Seco"
        }

        // ADICIONA A CLASSIFICAÇÃO OTM, ATM, ITM
        if(baseCopia[i]["Estrategia de Opcoes"] != "Trava"){
            if(baseCopia[i]["Estrategia de Opcoes"] != "Longa"){
                if(baseCopia[i]["Lado"] == "V"){
                    if(baseCopia[i]["tipo"] == "PUT"){
                        if(baseCopia[i]["strike"] < 0.99*baseCopia[i]["precoAcao"]){
                            baseCopia[i]["posicao no dinheiro"] = "OTM"
                        }else if(baseCopia[i]["strike"] < baseCopia[i]["precoAcao"]*1.01){
                            baseCopia[i]["posicao no dinheiro"] = "ATM"
                        }else{
                            baseCopia[i]["posicao no dinheiro"] = "ITM"
                        }
                    }else{
                        if(baseCopia[i]["strike"] < 0.99*baseCopia[i]["precoAcao"]){
                            baseCopia[i]["posicao no dinheiro"] = "ITM"
                        }else if(baseCopia[i]["strike"] < baseCopia[i]["precoAcao"]*1.01){
                            baseCopia[i]["posicao no dinheiro"] = "ATM"
                        }else{
                            baseCopia[i]["posicao no dinheiro"] = "OTM"
                        }
                    }
                }
            }
        }
    }

    const [baseFiltrada, setBaseFiltrada] = useState(baseCopia)

    // TODA VEZ QUE A BASE FILTRADA MUDAR
    useEffect(()=>{

        var baseNotionalVencimentoTemp = []

        if(baseFiltrada.length > 0){

            // MONTA OS DADOS PRO GRÁFICO DE VENCIMENTO
            // MONTA UM ARRY UNITÁRIO COM OS VALORES DE DATA DE VENCIMENTO EXISTENTES
            const datasVencimento = [...new Set(baseFiltrada.map((valor) => convertDataToText(valor.vencimento)))]

            // LIMPA AS DATAS 01/01/1900 QUE SÃO CRIADAS PARA OS TICKERS QUE NÃO ESTÃO NA BASE
            for(let dataIndex=0; dataIndex<datasVencimento.length;dataIndex++){
                if(datasVencimento[dataIndex] === "01-01-1900"){
                    datasVencimento.splice(dataIndex,1)
                }
            }

            // LOOP EM TODOS AS DATAS
            for(let j=0; j<datasVencimento.length;j++){

                // VARIÁVEI DE NOTIONAL
                var notionalVenc = 0

                // LOOP EM TODOS OS ITEMS
                for(let i=0; i<baseFiltrada.length;i++){
                    if(convertDataToText(baseFiltrada[i]["vencimento"])==datasVencimento[j]){
                        notionalVenc = notionalVenc + baseFiltrada[i]["notional"]
                    }

                }

                // MONTA A BASE DE IMPRESSÃO DO GRÁFICO POR VENCIMENTO
                if(datasVencimento[j] != undefined){
                    baseNotionalVencimentoTemp.push({
                        x: convertTextoToData(datasVencimento[j]),
                        y: notionalVenc
                    })
                }

            }

            // ATUALIZA A VARIÁVEL DE ESTADO
            setDadosGrafNotional(baseNotionalVencimentoTemp)

            // MONTA E QUEBRA POR ESTRATÉGIA
            // MONTA UM ARRAY UNITÁRIO DE VALORES DE TIPO
            const tipos = [...new Set(baseFiltrada.map((valor) => valor["Estrategia de Opcoes"]))]

            // LOOP EM TODOS OS TIPOS
            var notionalSeco=0
            var notionalLonga=0
            var notionalTrava=0
            var notionalTotal=0
            for(let j=0; j<tipos.length;j++){

                // VARIÁVEI DE NOTIONAL
                var notionalTipo = 0

                // LOOP EM TODOS OS ITEMS
                for(let i=0; i<baseFiltrada.length;i++){
                    if(baseFiltrada[i]["Estrategia de Opcoes"]==tipos[j]){
                        notionalTipo = notionalTipo + baseFiltrada[i]["notional"]
                    }
                }

                // ATUALIZA A VARIÁVEL DE ESTADO
                if(tipos[j]==="Seco"){
                    notionalSeco = notionalTipo
                }else if(tipos[j]==="Longa"){
                    notionalLonga = notionalTipo
                }else if(tipos[j]==="Trava"){
                    notionalTrava = notionalTipo
                }

                notionalTotal = notionalTotal + notionalTipo

            }

            // ATUALIZA A VARIÁVEL DE ESTADO DOS TIPOS DE NOTIONAL
            setDadosTipo((prev)=>({
                ...prev,
                seco:notionalSeco,
                trava:notionalTrava,
                longa:notionalLonga
            }))

            // ATUALIZA A VARIÁVEL DE ESTADO DO NOTIONAL TOTAL
            setNotionalTotal((prev)=>({
                ...prev,
                total:notionalTotal
            }))

            // MONTA A BASE POR OTM
            var notionalOTM=0
            var notionalATM=0
            var notionalITM=0
            // LOOP EM TODOS OS ITEMS
            for(let i=0; i<baseFiltrada.length;i++){
                if(baseFiltrada[i]["Estrategia de Opcoes"] != "Trava"){
                    if(baseFiltrada[i]["Estrategia de Opcoes"] != "Longa"){
                        if(baseFiltrada[i]["Lado"] == "V"){
                            if(baseFiltrada[i]["strike"] < 0.99*baseFiltrada[i]["precoAcao"]){
                                notionalOTM = notionalOTM + baseFiltrada[i]["notional"]
                            }else if(baseFiltrada[i]["strike"] < baseFiltrada[i]["precoAcao"]*1.01){
                                notionalATM = notionalATM + baseFiltrada[i]["notional"]
                            }else{
                                notionalITM = notionalITM + baseFiltrada[i]["notional"]
                            }
                        }
                    }
                }
            }

            // POPULA A VARIÁVEL TEMP
            var dadosOTMTemp = []
            dadosOTMTemp.push({
                y: notionalOTM,
                label: "OTM",
                color: "rgb(0,94,94)"
            })
            dadosOTMTemp.push({
                y: notionalATM,
                label: "ATM",
                color: "rgb(0,139,139)"
            })
            dadosOTMTemp.push({
                y: notionalITM,
                label: "ITM",
                color: "rgba(102,205,170,0.5)"
            })

            // ATUALIZA A VARIÁVEL DE ESTADO
            setDadosGrafOTM(dadosOTMTemp)
                
            // MONTA A DISTRIBUIÇÃO POR AÇÃO
            const acoes = [...new Set(baseFiltrada.map((valor) => valor["acao"]))]

            // ARRAY TEMP QUE VAI RECEBER AS INFOS
            var baseNotionalAcaoTemp = []

            for(let i=0; i< acoes.length; i++){
                // FILTRA A BASE DE OPERAÇÕES ATIVAS PARA SOMENTE AQUELA AÇÃO
                var searchConditions = [
                    {
                    key: 'acao',
                    value: acoes[i],
                    type: SearchType.EQ,
                    },
                ];
                var baseFiltradaAcao = filterData(baseFiltrada, searchConditions);

                // CAPTURA O NOTIONAL TOTAL DESSA AÇÃO
                var notionalAcao = 0
                for(let j=0; j< baseFiltradaAcao.length; j++){
                    notionalAcao = notionalAcao + baseFiltradaAcao[j]["notional"]
                }

                baseNotionalAcaoTemp.push({
                    acao:acoes[i],
                    notional:notionalAcao
                })

            }

            // RANKEIA DO MAIOR PRO MENOR
            baseNotionalAcaoTemp.sort((a, b) => b.notional - a.notional);

            // ATUALIZA A VARIÁVEL DE ESTADO
            setBaseNotionalAcao(baseNotionalAcaoTemp)

            // MONTA O DADOS DE PROVISÃO E CRASH
            var provisaoTotal = 0
            var provisaoTotalQueda = 0
            for(let ativo=0; ativo < baseFiltrada.length; ativo++){
                provisaoTotal = provisaoTotal + baseFiltrada[ativo]["provisao"]
                provisaoTotalQueda = provisaoTotalQueda + baseFiltrada[ativo]["provisaoQueda"]
            }

            // ATUALIZA A VARIÁVEL DE ESTADO DO NOTIONAL TOTAL
            setProvisao((prev)=>({
                ...prev,
                provisao:provisaoTotal,
                provisaoQueda: provisaoTotalQueda
            }))

        }

    },[baseFiltrada])

    // FUNÇÃO QUE ADICIONA MAIS UM PARAMETRO A LISTA DE FILTROS
    function addparameter(parametro, valor){
        var parametersInicial = JSON.parse(JSON.stringify(parametersFiltro))
        parametersInicial.push({parametro: parametro, valor: valor})
        setParametersFiltro(parametersInicial)

        // EDITA DE ACORDO COM A SELEÇÃO DO TIPO
        if(parametro === "Estrategia de Opcoes"){
            if(valor === "Longa"){
                tipoLonga.current.classList.remove(`${styles.notTipoItemOculto}`)
                tipoLonga.current.classList.add(`${styles.notTipoItemDestaque}`)
                tipoSeco.current.classList.add(`${styles.notTipoItemOculto}`)
                tipoSeco.current.classList.remove(`${styles.notTipoItemDestaque}`)
                tipoTrava.current.classList.add(`${styles.notTipoItemOculto}`)
                tipoTrava.current.classList.remove(`${styles.notTipoItemDestaque}`)

            }else if (valor === "Trava"){
                tipoTrava.current.classList.remove(`${styles.notTipoItemOculto}`)
                tipoTrava.current.classList.add(`${styles.notTipoItemDestaque}`)
                tipoSeco.current.classList.add(`${styles.notTipoItemOculto}`)
                tipoSeco.current.classList.remove(`${styles.notTipoItemDestaque}`)
                tipoLonga.current.classList.add(`${styles.notTipoItemOculto}`)
                tipoLonga.current.classList.remove(`${styles.notTipoItemDestaque}`)

            }else{
                tipoSeco.current.classList.remove(`${styles.notTipoItemOculto}`)
                tipoSeco.current.classList.add(`${styles.notTipoItemDestaque}`)
                tipoLonga.current.classList.add(`${styles.notTipoItemOculto}`)
                tipoLonga.current.classList.remove(`${styles.notTipoItemDestaque}`)
                tipoTrava.current.classList.add(`${styles.notTipoItemOculto}`)
                tipoTrava.current.classList.remove(`${styles.notTipoItemDestaque}`)

            }

        }
    }

    // FUNÇÃO DE FILTRO DA BASE QUE RECEBE O PARÂMETRO SEARCH
    useEffect(()=>{

        var basecopia = baseFiltrada

        for(let i = 0; i <parametersFiltro.length; i++){

            if(parametersFiltro[i]["parametro"] === "vencimento"){

                var newArray = []
                for(let j = 0; j < baseFiltrada.length; j++){

                    var valorData = new Date(parametersFiltro[i]["valor"])
                    var valorParametro = new Date(baseFiltrada[j]["vencimento"])
                    if(convertDataToText(valorData) == convertDataToText(valorParametro)){
                        newArray.push(baseFiltrada[j])
                    }
                    
                }

                basecopia = newArray

            }else{
                
                // FILTRA A BASE COMPLETA DE ACORDO COM A AÇÃO
                var searchConditions = [
                    {
                    key: parametersFiltro[i]["parametro"],
                    value: parametersFiltro[i]["valor"],
                    type: SearchType.EQ,
                    },
                ];

                basecopia = filterData(basecopia, searchConditions)

            }
            
        }

        setBaseFiltrada(basecopia)

    },[parametersFiltro])

    // FUNÇÃO DE RESET DA BASE
    function reset(){
        // RETORNA A BASE PARA O PADRÃO
        setBaseFiltrada(props.base)

        // ZERA O ARRAY DE PARÂMETROS
        setParametersFiltro([])

        // RETORNA A FORMATAÇÃO DOS TIPOS DE OPERAÇÃO
        tipoLonga.current.classList.remove(`${styles.notTipoItemOculto}`)
        tipoLonga.current.classList.remove(`${styles.notTipoItemDestaque}`)
        tipoSeco.current.classList.remove(`${styles.notTipoItemOculto}`)
        tipoSeco.current.classList.remove(`${styles.notTipoItemDestaque}`)
        tipoTrava.current.classList.remove(`${styles.notTipoItemOculto}`)
        tipoTrava.current.classList.remove(`${styles.notTipoItemDestaque}`)
    }

    // CONSTANTES DE IMPRESSÃO DOS GRÁFICOS
    const optionsNotionalVencimento = {
        animationEnabled: true,
		backgroundColor: "transparent",
        height:190,
        width:460,
		legend:{
			fontColor: "rgb(150,150,150)",
            verticalAlign: "top",
            fontSize:0,
            fontFamily: fonte
		},
        toolTip:{
            shared: true,
            fontColor: "gray",
            fontFamily: fonte,
            contentFormatter: function (e) {              
                let RSLocale = Intl.NumberFormat('pt-BR');             
				var content = "<strong>" + e.entries[0].dataPoint.x.toLocaleDateString() +"</strong>" + "<table>";
				for (var i = 0; i < e.entries.length; i++) {
                    content += "<tr/>";
					content += "<th style='padding-right:10px'><span style = 'color:gray'>" + e.entries[i].dataSeries.name + ": </span></th> " + "<th><span style='color:gray'> R$ " + RSLocale.format(parseInt(e.entries[i].dataPoint.y)) + "</span></th>";
					content += "<tr/>";
				}
                content += "</table>"
				return content;
            }
        },
		title:{
			text: "",
			padding:0,
			fontSize:0,
			fontColor: "rgba(102,205,170,1.0)",
			fontFamily: fonte
		},
		axisY: {
			title: "",
			gridThickness: 0,
			labelFontSize:0.0000000000001,
			lineThickness:0,
			tickThickness: 0,
            labelFontFamily: fonte
		},
		axisX:{
			gridThickness: 0,
			margin: 10,
			tickThickness: 0,
            labelFontSize:9,
			labelFontColor: "white",
			lineColor: "white",
			lintThickness: 1,
            intervalType: "month",
            labelFontFamily: fonte,
		},
		data: [{
            click: function (e) {   
                addparameter("vencimento", e.dataPoint.x)            
            },
            cursor: "pointer",
			type: "column",
            name: "Notional",
			showInLegend: true,
            indexLabelFontFamily: fonte,
            indexLabel: "{y}",
            indexLabelFontSize: 10,
            indexLabelFontColor:"white",
			legendText: "Notional",
			color: "white",
			markerSize: 5,
            indexLabelFormatter:function (e) {       
                if(e.dataPoint.y === 0){
                    var content = ""
                }else{
                    var content = parseInt(e.dataPoint.y/1000) + "K";
                }       
                return content;
            },
			xValueFormatString: "MM/YYYY",
			yValueFormatString: "R$#,##0.##",
			dataPoints: dadosGrafNotional
		}]
    }

    const optionsOTM = {
        backgroundColor: "transparent",
        animationEnabled: true,
        height: 195,
        title:{
            text: "Posicão TM",
            padding:0,
            fontSize:0, //20
            fontColor: "rgba(102,205,170,1.0)",
            fontFamily: fonte
        },
        toolTip:{
            enabled: true,
            shared: true,
            fonteFamily: fonte
            },
        data: [{
            click: function (e) {   
                addparameter("posicao no dinheiro", e.dataPoint.label)            
            },
            cursor: "pointer",
            type: "doughnut",
            startAngle: 60,
            radius: "100%",
            innerRadius: "85%",
            indexLabelFontSize: 12,
            indexLabelMinWidth: 20,
            indexLabelFontFamily: fonte,
            indexLabelWrap: true, 
            indexLabel: "{label}  (#percent%)",
            toolTipContent: "<b>{label}:</b>(#percent%)",
            indexLabelFontColor: "rgb(100,100,100)",
            dataPoints: dadosGrafOTM
        }]
    }

    return(
        <div className={`${styles.corpo}`}>
            <div className={`${styles.secao1}`}>
                <div className={`${styles.notVencimento}`}>
                    <div className={`${styles.notNominal}`}>Notional Nominal: {RSLocale.format(parseFloat(props.input.notionalTotal).toFixed(0))}</div>
                    <GraficoLimpo id="graf-proj-not-vencimento" options = {optionsNotionalVencimento}/>
                </div>
                <div className={`${styles.notTipo}`}>
                    <div ref={tipoSeco} className={`${styles.notTipoItem} ${styles.seco}`} onClick={(e) => addparameter("Estrategia de Opcoes", "Seco")}>
                        <div className={`${styles.titulo}`}>Seco</div>
                        <div>{RSLocale.format(dadosTipo.seco.toFixed(0))}</div>
                        <div>{(dadosTipo.seco/props.input.notionalTotal*100).toFixed(0)}%</div>
                    </div>
                    <div ref={tipoLonga} className={`${styles.notTipoItem} ${styles.longa}`} onClick={(e) => addparameter("Estrategia de Opcoes", "Longa")}>
                        <div className={`${styles.titulo}`}>Longa</div>
                        <div>{RSLocale.format(dadosTipo.longa.toFixed(0))}</div>
                        <div>{(dadosTipo.longa/props.input.notionalTotal*100).toFixed(0)}%</div>
                    </div>
                    <div ref={tipoTrava} className={`${styles.notTipoItem} ${styles.trava}`} onClick={(e) => addparameter("Estrategia de Opcoes", "Trava")}>
                        <div className={`${styles.titulo}`}>Trava</div>
                        <div>{RSLocale.format(dadosTipo.trava.toFixed(0))}</div>
                        <div>{(dadosTipo.trava/props.input.notionalTotal*100).toFixed(0)}%</div>
                    </div>
                </div>
            </div>
            <div className={`${styles.secao2}`}>
                <div className={`${styles.distOTM}`}>
                    <div className={`${styles.mensagem}`}>PUT's secas somente</div>
                    <GraficoLimpo id="graf-dist-otm" options = {optionsOTM}/>
                </div>
                <div className={`${styles.summary}`}>
                    <div className={`${styles.total}`}>
                        <div className={`${styles.titulo}`}>Provisão</div>
                        <div>{RSLocale.format(provisao.provisao.toFixed(0))}</div>
                        <div>{RSLocale.format((provisao.provisao/props.input.notionalTotal*100).toFixed(0))}%</div>
                    </div>
                    <div className={`${styles.ponderado}`}>
                        <div className={`${styles.titulo}`}>Crash</div>
                        <div>{RSLocale.format(provisao.provisaoQueda.toFixed(0))}</div>
                        <div>{RSLocale.format((provisao.provisaoQueda/props.input.notionalTotal*100).toFixed(0))}%</div>
                    </div>
                </div>
            </div>
            <div className={`${styles.secao3}`}>
                <div className={`${styles.listaAcoes}`}>
                    <div className={`${styles.listaAcoesCorpo}`}>
                        {baseNotionalAcao.map((acao) =>{  
                            return(
                                <div key={"itemNotional" + acao.acao} className={`${styles.listaAcoesTitulo}`}>
                                    <div className={`${styles.linhaAcoes}`}>
                                        <div className={`${styles.items} ${styles.itemAcao}`} onClick={(e) => addparameter("acao", acao.acao)}>{acao.acao}</div>
                                        <div className={`${styles.items}`}>{RSLocale.format(acao.notional)}</div>
                                        <div className={`${styles.items}`}>{(acao.notional/notionalTotal.total*100).toFixed(0)}%</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className={`${styles.secaoReset}`}>
                <Button variant="secondary" onClick={(e) => reset()}>
                    Reset
                </Button>
            </div>
        </div>
    )
}

export default DetalheNotional