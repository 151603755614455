import LinhaClienteRisco from './linhasClientes';
import styles from './PainelRisco.module.css';
import { useState, useEffect, useRef } from 'react';
import { filterData, SearchType } from 'filter-data';
import { OverlayTrigger, Popover } from 'react-bootstrap';

// MÓDULO DE GESTÃO DE RISCO
const PainelRisco = (props) => {

    // CAPTURA A BASE DE RISCO
    const[tabelaRisco, setTabelaRisco] = useState([])

    // VARIÁVEL QUE CONTA LINHAS ÍMPARES E PARES
    const linhaCont = useRef(0);

    // CONSTANTES DE POPOVER
    const popoverMargem = (
        <Popover id="popover-margem" >
            <Popover.Header className={`${styles.popoverTitulo}`}>Cálculo de <b>Margem</b></Popover.Header>
            <Popover.Body>
                <div className={`${styles.popoverCorpo}`}>
                    <div>Considera a <b>direção</b> na qual o <b>cliente tem mais exposição</b></div>
                    <div>Pega o <b>maior valor dentre as margens</b> de Puts e Calls</div>
                </div>
            </Popover.Body>
        </Popover>
    );

    const popoverNotional = (
        <Popover id="popover-notional" >
            <Popover.Header className={`${styles.popoverTitulo}`}>Cálculo do <b>Notional</b></Popover.Header>
            <Popover.Body>
                <div className={`${styles.popoverCorpo}`}>
                    <div><b>Não</b> há ponderação de <b>Notional pelo vencimento</b></div>
                    <div><b>Proteções</b> compradas <b>descontam</b> o consumo de notional</div>
                    <div>Operações de risco limitado (Travas) tem seu <b>notional associado a perda máxima</b></div>
                </div>
            </Popover.Body>
        </Popover>
    );

    const popoverCrash = (
        <Popover id="popover-notional" >
            <Popover.Header className={`${styles.popoverTitulo}`}>Cálculo do <b>Crash</b></Popover.Header>
            <Popover.Body>
                <div className={`${styles.popoverCorpo}`}>
                    <div>Crash considera um cenário de <b>queda de 30%</b> do mercado</div>
                    <div>Ele simula qual seria o <b>tamanho da provisão.</b></div>
                </div>
            </Popover.Body>
        </Popover>
    );

    const popoverMargemB3 = (
        <Popover id="popover-notional" >
            <Popover.Header className={`${styles.popoverTitulo}`}>Cálculo da <b>Margem B3</b></Popover.Header>
            <Popover.Body>
                <div className={`${styles.popoverCorpo}`}>
                    <div>Simula qual é o valor aproximado de margem puxada pela B3</div>
                    <div>MP = Margem puxada pelas Puts</div>
                    <div>MC = Margem puxada pelas Calls</div>
                </div>
                <div className={`${styles.popoverDestaque}`}>MB3 = MP*<b>0.2</b> + MC*<b>1</b></div>
            </Popover.Body>
        </Popover>
    );

    const popoverTrava = (
        <Popover id="popover-trava" >
            <Popover.Header className={`${styles.popoverTitulo}`}>Cálculo do <b>Trava %</b></Popover.Header>
            <Popover.Body>
                <div className={`${styles.popoverCorpo}`}>
                    <div>O % é a exposição total em travas vs todos os prêmios ja fetios.</div>
                </div>
                <div className={`${styles.popoverDestaque}`}>(Not Travas / <b>Premios históricos gerados</b>)</div>
            </Popover.Body>
        </Popover>
    );

    const popoverProvisaoHoje = (
        <Popover id="popover-trava" >
            <Popover.Header className={`${styles.popoverTitulo}`}>Cálculo de <b>Provisão</b></Popover.Header>
            <Popover.Body>
                <div className={`${styles.popoverCorpo}`}>
                    <div>Expectativa de provisão baseada nas posições abertas hoje</div>
                </div>
                <div className={`${styles.popoverDestaque}`}>&#8721; quantidade executada * <b>preço atual da opção vendida</b></div>
            </Popover.Body>
        </Popover>
    );

    const popoverProvisaoIntr = (
        <Popover id="popover-trava" >
            <Popover.Header className={`${styles.popoverTitulo}`}>Valor <b>Intrínsico / Extrínsico</b></Popover.Header>
            <Popover.Body>
                <div className={`${styles.popoverCorpo}`}>
                    <div>Valor extrínsico é valor de risco da opção calculado por Black - Scholes.</div>
                    <div>O valor intrínsico é o quanto do valor da opção se deve ao fato de ela estar ITM</div>
                    <div>O cálculo abaixo reflete o valor intrínsico e é feito para PUT's, para CALL's é o cenário inverso</div>
                </div>
                <div className={`${styles.popoverDestaque}`}>&#8721; Qtd * <b>(strike - preço ação</b>)</div>
            </Popover.Body>
        </Popover>
    );

    const popoverVarPatrimonio = (
        <Popover id="popover-trava" >
            <Popover.Header className={`${styles.popoverTitulo}`}>Variação <b>Patrimônio</b></Popover.Header>
            <Popover.Body>
                <div className={`${styles.popoverCorpo}`}>
                    <div>O quanto o patrimônio final vai variar como consequência dos premios gerados e da variação da provisão.</div>
                    <div>O cálculo abaixo simboliza o impacto na marcação a mercado.</div>
                </div>
                <div className={`${styles.popoverDestaque}`}>&#8721; Prêmios gerados - (<b>Aumento da provisão</b>)</div>
            </Popover.Body>
        </Popover>
    );

    // TODA VEZ QUE A PÁGINA CARREGAR, ALTERA A VARIÁVEL DE ESTADO
    useEffect(()=>{
        linhaCont.current = 0
        setTabelaRisco(props.baseRisco)
    })

    return(
        <section className={`${styles.secao}`}>
            <h1>
                <div className="sub-titulo-group">
                    <div>
                        <span className="material-symbols-outlined icon-title">health_and_safety</span>Indicadores de Risco
                    </div>
                </div>
            </h1>
            <div className={`${styles.painelGeral}`}>
                <div className={`${styles.painelTitulo}`}>
                    <div className={`${styles.users}`}>
                        <div className={`${styles.tituloPainel}`}>
                            <div className={`${styles.tituloPrincipal}`}>Clientes</div>
                            <div className={`${styles.subtitulo}`}></div>
                        </div>
                    </div>
                    <div className={`${styles.macrobloco} ${styles.bigView}`}>
                        <div className={`${styles.tituloPainel}`}>
                            <div className={`${styles.tituloPrincipal}`}>Risco</div>
                            <div className={`${styles.subtitulo}`}>
                                <div className={`${styles.coluna} ${styles.bigView}`}>&#916;-&#946;</div>
                                <div className={`${styles.coluna} ${styles.bigView}`}>&#916;-&#946; %</div>
                                <div className={`${styles.coluna} ${styles.bigView}`}>Risco &#947;</div>
                                <div className={`${styles.coluna} pointer`}>
                                    <OverlayTrigger placement="top" overlay={popoverTrava} delay={{ show: 100, hide: 100 }}>
                                        <div>% Trv &#x1F6C8;</div>
                                    </OverlayTrigger>    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.macrobloco}`}>
                        <div className={`${styles.tituloPainel}`}>
                            <div className={`${styles.tituloPrincipal}`}>Renda</div>
                            <div className={`${styles.subtitulo}`}>
                                <div className={`${styles.coluna} ${styles.bigView}`}>&#952;</div>
                                <div className={`${styles.coluna} ${styles.bigView}`}>Range</div>
                                <div className={`${styles.coluna} ${styles.bigView}`}>Prêmios</div>
                                <div className={`${styles.coluna}`}> P %</div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.macrobloco}`}>
                        <div className={`${styles.tituloPainel}`}>
                            <div className={`${styles.tituloPrincipal}`}>Notional</div>
                            <div className={`${styles.subtitulo}`}>
                                <div className={`${styles.coluna} ${styles.bigView}`}>Nominal</div>
                                <div className={`${styles.coluna} ${styles.bigView} pointer`}>
                                    <OverlayTrigger placement="top" overlay={popoverNotional} delay={{ show: 100, hide: 100 }}>
                                        <div>Utilizado &#x1F6C8;</div>
                                    </OverlayTrigger>
                                </div>
                                <div className={`${styles.coluna}`}>N %</div>
                                <div className={`${styles.coluna} ${styles.bigView} pointer`}>
                                    <OverlayTrigger placement="top" overlay={popoverCrash} delay={{ show: 100, hide: 100 }}>
                                        <div>Crash &#x1F6C8;</div>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.macrobloco}`}>
                        <div className={`${styles.tituloPainel}`}>
                            <div className={`${styles.tituloPrincipal}`}>Margem</div>
                            <div className={`${styles.subtitulo}`}>
                                <div className={`${styles.coluna} ${styles.bigView}`}>Margem Put</div>
                                <div className={`${styles.coluna} ${styles.bigView}`}>Margem Call</div>
                                <div className={`${styles.coluna} pointer`}>
                                    <OverlayTrigger placement="top" overlay={popoverMargem} delay={{ show: 100, hide: 100 }}>
                                        <div>Margem &#x1F6C8;</div>
                                    </OverlayTrigger>
                                </div>
                                <div className={`${styles.coluna} ${styles.bigView} pointer`}>
                                    <OverlayTrigger placement="top" overlay={popoverMargemB3} delay={{ show: 100, hide: 100 }}>
                                        <div>Margem B3 &#x1F6C8;</div>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.painelCorpo}`}>
                    {tabelaRisco.map((objeto) => {
                        // ATUALIZA O INDEX DA LINHA
                        linhaCont.current = linhaCont.current + 1

                        // ATUALIZA O KEY DA LINHA
                        var key = "linhaRisco" + linhaCont.current
                        var index = linhaCont.current*1

                        // CAPTURA A CHAVE DO CLIENTE
                        var chave = objeto.chaveCliente

                        // FILTRA O CLIENTE
                        var searchConditions = [
                            {
                            key: 'chaveCliente',
                            value: chave,
                            type: SearchType.EQ,
                            },
                        ];
                        var baseKPIFiltrada = filterData(props.baseKPIRiscoDetalhe, searchConditions);

                        // PASSA SOMENTE A BASE FILTRADA DO CLIENTE COMO PROPS
                        // VERIFICA SE É ÍMPAR OU PAR
                        if(linhaCont.current%2 == 0){
                            return(<LinhaClienteRisco input={objeto} index={index} key={key} lineColor="white" linhaCont={linhaCont.current} baseKPIDetalhe={baseKPIFiltrada}/>)
                        }else{
                            return(<LinhaClienteRisco input={objeto} index={index} key={key} lineColor="gray" linhaCont={linhaCont.current} baseKPIDetalhe={baseKPIFiltrada}/>)
                        }

                    })}
                </div>
            </div>
        </section>
    )
}

export default PainelRisco;